.legal-notices {
    display: flex;
    flex-direction: column;
    background-color: white;
    height: 100%;
}
.legal-notices-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 60px auto;
    padding: 50px 10px 50px 10px;
    font-family: $font-2;
    img {
        height: 250px;
        width: auto;
        object-fit: cover;
        margin-right: 100px;
    }
    p {
        font-size: 1rem;
        max-width: 60ch;
    }
    h1 {
        font-size: 1.5rem;
        margin: 10px 0px;
        padding: 10px 0px 40px 0px;
    }
    .legal-notices-text {
        margin-left: 40px;
    }
}

@media screen and (max-width: 1280px) and (min-height: 790px) {
    .legal-notices-container {
        margin: 15vh auto 140px auto;
    }
}

@media screen and (max-width: 1000px) {
    .legal-notices-container {
        flex-direction: column;
        justify-content: space-around;
        text-align: center;
        margin: 6vh auto 10vh auto;
        img {
            height: 250px;
            margin: 10px 70px;
        }
        p {
            margin: 20px 70px;
            text-align: left;
        }
        .legal-notices-text {
            margin: 60px 70px;
        }
    }
}

@media screen and (max-width: 700px) {
    .legal-notices-container {
        margin: 20px;
        margin-bottom: 5vh;
        img {
            height: 150px;
            margin: 10px;
        }
        .legal-notices-text {
            margin: 20px;
            p {
                margin: 10px;
            }
        }
    }
}
@media screen and (max-width: 400px) {
    .legal-notices-container{
        margin: 8vh 10px;
        margin-bottom: 8vh;
        padding: 10px;
        img {
            height: 110px;
            margin: 10px;
        }
        .legal-notices-text {
            margin: 15px;
            p {
                margin: 15px;
            }
        }
    }
}
@media screen and (max-width: 1000px) and (min-height: 1300px) {
    .legal-notices-container {
        margin: 100px auto 20vh auto;
        img {
            height: 250px;
        }
    }
    .legal-notices-text {
        h1 {
            margin: 50px;
        }
    }
}