.privacy-policy {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.privacy-policy-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 65ch;
    margin: 20px;
    padding: 10px;
    font-family: $font-2;
    font-size: 1rem;
    
    h1 {
        padding: 5px 0px;
    }
    p {
        padding: 0px;
    }
    h2 {
        padding: 5px 0px;
    }
    h3 {
        padding: 5px 0px;
    }
    ul {
        padding: 5px 0px;
        li {
            list-style-type: none;
            padding: 7px 0px;
        }

    }
    
}

@media screen and (max-width: 400px) {
    .privacy-policy-container {
        h1 {
            font-size: 1.7rem;
        }
        h2 {
            font-size: 1.6rem;}
    }
}