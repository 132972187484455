.quote {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 20px;
}

.author {
    font-family: $font-2;
    font-size: 1rem;
    text-align: center;
    padding: 0px 10px;
    margin: 0px 10px;
}

.quote-text {
    padding: 50px 10px 10px 10px;
    margin: 10px;
    font-size: 1.3rem;
    font-family: $quote;
    text-align: center;
}

.card-list-below-quote {
    display: flex;
    flex-wrap: wrap;
    padding-top: 30px;
    padding-left: 100px;
    padding-right: 100px;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .author-name {
      font-weight: bold;
  }

  @media screen and (max-width: 1300px) {
    .card-list-below-quote {
      padding-left: 50px;
      padding-right: 50px;
    }
    .quote {
      margin: 0px 100px;
    }
  }

  @media screen and (max-width: 700px) {
    .card-list-below-quote {
      padding-left: 50px;
      padding-right: 50px;
    }
    .quote {
      margin: 0px 10px;
    }

    .author {
      padding-left: 25px;
      padding-right: 25px;
    }
    .quote-text {
      padding-left: 15px;
      padding-right: 15px;
    }
  }