.about-container {
  display: flex;
  justify-content: center;
  margin: 170px 0px 60px 0px; 
}

.about {
  display: flex;
  justify-content: center;
  margin: 0px;
  padding: 1vh 6vw;
  border-radius: .3rem;
  max-width: 52vw;
  p {
    text-align: center;
    font-family: $font-2;
    font-size: 1.1rem;
  a {
  text-decoration: none;
  color: $blue;
  font-weight: bold;
  }
  }
}

.link-underline:hover {
  text-decoration: underline;
}

@media screen and (max-width: 1000px) {
    .about {
      padding: .7vh 2vw;
      max-width: 50vw;  
    }
}

@media screen and (max-width: 600px) {
  .about-container {
    margin: 20px 15px;
  }
    .about {
      padding: 30px 0px;
    }
}