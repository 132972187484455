.contact-form {
    display: flex;
    justify-content: center;
}
.form-container {
    padding: 40px 6vw;
    background-color: white;
    border-radius: .3rem;
    max-width: 45vw;
    margin-bottom: 20px;
    form {
    display: flex;
    flex-direction: column;
    font-family: $font-2;
    font-size: 1.1rem;
    }
    .form-header {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 25px 5px 10px 5px;
    }
    h1 {
        padding: 0px 0px 30px 0px;
        margin: 0px;
        text-align: center;
        font-family: $font-2;
    }
    img {
        font-family: $font-3;
        object-fit: contain;
        height: 90px;
        width: 90px;
        padding: 0px;
        margin: -10px 0px 0px 0px;
    }
    input {
        padding: 5px;
        margin: 25px 10px 15px 10px;
        width: 18vw;
        font-size: 1.1rem;
        font-family: $font-2;
        border-top: hidden;
        border-left: hidden;
        border-right: hidden;
        border-bottom: .5px solid black;
        background-color: white;
    }
    input:focus {
        outline: none;
        border-bottom: 1px solid;
        border-image: linear-gradient( 90deg, #e8751a, #f8da5b, #f8da5b, #e8751a)2;
    }
    textarea {
        padding: 5px;
        margin: 25px 10px 20px 10px;
        width: 40vw;
        font-size: 1.1rem;
        font-family: $font-2;
        resize: none;
        border: none;
        border-bottom: .5px solid black;
        border-radius: .1rem;
    }
    textarea:focus {
        outline: none;
        border-bottom: 1px solid;
        border-image: linear-gradient( 90deg, #e8751a, #f8da5b, #f8da5b, #e8751a)2;
    }
    .subject {
        padding: 5px;
        margin: 15px 10px 20px 10px;
        width: 40vw;
        border-radius: .1rem;
    }
    .form-button {
        margin: 15px 10px;
        width: 15vw;
        padding: 10px;
        font-size: 1.1rem;
        font-family: $font-2;
        align-self: center;
        color: white;
        background-color: $blue;
        border: none;
        border-radius: .3rem;
    }
    .form-button:hover {
        opacity: 85%;
        cursor: pointer;
    }
    .form-line-1 {
        display: flex;
        justify-content: space-between;
    }
}

.error-message {
    font-size: .9rem;
    color: $red;
    margin: -15px 5px;
    padding: 5px;
}

@media screen and (max-width: 1000px) {
    .form-container {
        max-width: 70vw;
        padding: 40px 11vw;
    }
    .form-line-1 {
        display: flex;
        flex-direction: column;
    }

    .form-container {

        input {
        width: 100%;
        margin: 20px 0px;
        padding: 5px 0px;
    }
        .subject {
            width: 100%;
            margin: 20px 0px;
            padding: 5px 0px;
        }
      textarea {
        margin: 20px 0px;
        padding: 5px 0px;
        width: 100%;
      }

      .error-message-textarea {
          padding: 0px 5px 15px 5px;
          margin: -15px 5px 10px 5px;
          width: 100%;
      }
      .form-line-3 {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
  }
}

@media screen and (max-width: 600px) {
    .form-container {
        max-width: 100%;
        width: 100%;
        padding: 10px 40px;
        margin: 0px 20px;
        textarea {
            margin: 20px 0px;
            padding: 5px 0px;
            width: 100%;
        }
        .form-button {
            margin: 15px 10px;
            width: 50%;
            padding: 10px;
            font-size: 1.1rem;
            font-family: $font-2;
            align-self: center;
            color: white;
            background-color: $blue;
            border: none;
            border-radius: .3rem;
        }
    }
}