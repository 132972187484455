.footer {
    margin: 40px 0px 5px 0px;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: $font-2;
    font-size: 1rem;
    text-align: center;
    p {
        text-decoration: none;
        padding-right: 5px;
        color: black;
        .title-link {
            padding: 0px;
        }
    }
    a {
        padding: 0 10px;
        text-decoration: none;
        color: black;
    }
    a:hover {
        text-decoration: underline;
        span {
            text-decoration: underline;
        }
    }
 }

 .title-span {
     text-decoration: none;
 }
 .title-span:hover {
     text-decoration: underline;
 }

 .copyright {
    border-right: solid $grey 1px;
}

 .privacy-policy-link {
     border-left: solid $grey 1px;
     border-right: solid $grey 1px;
 }

@media screen and (max-width: 1000px) {
    .footer {
        padding-bottom: 20px;
        p {
            padding-right: 5px;
        }
    }
}
@media screen and (max-width: 700px) {
    .footer {
        margin: 10px;
        padding: 10px;
        width: auto;
        padding-bottom: 20px;
        p, a {
            font-size: .9rem;
        }
        p {
            padding-right: 5px;
        }
    }
}