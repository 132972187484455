.characters {
    padding: 0px;
    margin:  0px;
}

.scroll-menu {
    margin: 0px;
    padding: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    width: 100%;
    z-index: 1;
    background-color: white;
}
.button-container {
    padding: 0px;
    margin: 0px;
    overflow-y: hidden;
    overflow-x: auto;
    height: 60px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    scroll-behavior: smooth;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.button-container::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
.button-container {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

.game-button {
    margin: 0px;
    height: 35px;
    cursor: pointer;
    background-color: white;
    border: none;
    padding: 10px 30px;
    width: 180px;
    object-fit: contain;
    text-align: center;
    font-family: $font-3;
}

.game-button:hover {
    transform: scale(1.3)
}

.prev {
    height: 35px;
    width: 20px;
    border: none;
    cursor: pointer;
    background-color: white;
}
.next {
    height: 35px;
    width: 20px;
    border: none;
    cursor: pointer;
    background-color: white;
}

.loading-button {
    justify-self: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0px;
    height: 35px;
    cursor: pointer;
    background-color: white;
    border: none;
    padding: 10px 30px;
    width: 180px;
    object-fit: contain;
    text-align: center;
    animation: button 1s infinite ease;
}

@keyframes button {
    0%{opacity: 0}
    50%{opacity: 1}
    100%{opacity: 0}
}

.hidden-button {
    visibility: hidden;
    height: 0px;
    width: 0px;
}