@import "./settings.scss";
@import "./components/header.scss";
@import "./components/about.scss";
@import "./components/card.scss";
@import "./UI/loadingspinner";
@import "./components/contact.scss";
@import "./components/menuscroll";
@import "./components/creatures";
@import "./components/footer";
@import "./components/legalnotices";
@import "./components/privacypolicy";
@import "./components/notfound";

@font-face  {
    font-family: 'Final Fantasy';
    src: url('./fonts/FinalFantasy.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
