.card-list {
  display: flex;
  flex-wrap: wrap;
  padding-top: 110px;
  padding-left: 100px;
  padding-right: 100px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.flip-card {
    margin: 15px;
    border-radius: 1%;
    height: 300px;
    width: 250px;
    align-items: center;
    perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  padding: 0px;
  background-color: white;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.flip-card-front {
  color: black;
  font-family: $font-3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  box-shadow: 0 5px 5px 0 $grey, 2px 0 2px 0 $grey;
  font-size: 19px;
  list-style-type: none;
  border-top: 2px solid;
  border-image: linear-gradient( 90deg, #e8751a, #f8da5b, #f8da5b, #e8751a)2;
    ul {
      margin-top: 15px;
      padding-left: 25px;
      padding-right: 25px;
      text-align: center;
    }
    li {
      list-style-type: none;
    }
}

img {
  text-align: center;
  object-fit: contain;
  padding: 0px;
  height: 100px;
  width: 100px;
  font-family: $font-3;
}

.game-picture-brave {
  object-fit: cover;
}

.game-title {
  text-decoration: underline;
}
.flip-card-back {
  font-family: $font-3;
  transform: rotateY(180deg);
  align-items: center;
  box-shadow: 0 5px 5px 0 $grey, 2px 0 2px 0 $grey;
  list-style-type: none;
  border-top: 2px solid;
  border-image: linear-gradient( 90deg, #e8751a, #f8da5b, #f8da5b, #e8751a)2;
  text-align: center;
  display: flex;
  justify-content: center;
  h6, h5 {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media screen and (max-width: 1300px) {
  .card-front {
    height: 250px;
    width: 200px;
    font-size: 1.2rem;
  }
  .card-list {
    padding-left: 50px;
    padding-right: 50px;
  }
  .flip-card-back {
    font-size: 1.2rem;
  }
}