.header-container {
  margin-bottom: 0px;
  padding-bottom: 0px;
  top: 0;
  position: fixed;
  margin-top: 0;
  width: 100%;
  z-index: 2;
  border-bottom: 1.4px solid;
  border-image: linear-gradient( 90deg, #e8751a, #f8da5b, #f8da5b, #e8751a)2;
  
}
.line {
  display: flex;
  justify-content: space-between;
  color: white;
  background-color: $black;
  height: 40px;
  max-height: 40px;
  align-items: center;
  font-family: $font-1;
  font-size: 1.4rem;
  line-height: 0px;
  padding: 2px 30px;
}

.icon {
  text-decoration: none;
  color: white;
}

.icon:hover {
  color: $yellow;
  box-shadow: 0 0 15px $yellow;
}

.header {
  display: flex;
  margin-bottom: 0px;
  padding-bottom: 0px;
  background-color: white;
}

.navigation {
  margin-top: 0px;
  margin-left: 0px;
  margin-bottom: 0px;
  padding: 11px 15px;
  height: 35px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background-color: white;
  width: 100%;
  a {
    color: $black;
    font-family: $font-3;
    font-size: 1.2rem;
    margin-right: 50px;
    position: relative;
    text-decoration: none;
    &::after {
      content:"";
      width: 0%;
      height: 1.3px;
      background: $yellow;
      position: absolute;
      bottom: -3px;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 2px;
    }
  }
  .nav-active {
    &::after {
      content:"";
      width: 90%;
      height: 1.3px;
      background: $yellow;
      position: absolute;
      bottom: -3px;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 2px;
    }
  }
  a:hover::after {
        content:"";
        width: 90%;
        height: 1.3px;
        background: $yellow;
        position: absolute;
        bottom: -3px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: -3px;
        transition: 0.5s ease all;
  }
}

.logo {
img {
  padding: 11px 15px;
  margin: 0px;
  width: 70px;
  height: 35px;
  background-color: white;
  font-family: $font-3;
}
}

.menu-button-container {
  display: none;
  height: 100%;
  width: 30px;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
}

#menu-toggle {
  display: none;
}

.menu-button,
.menu-button::before,
.menu-button::after {
  display: block;
  background-color: $black;
  position: absolute;
  height: 4px;
  width: 30px;
  transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
  border-radius: 2px;
 
}

.menu-button::before {
  content: '';
  margin-top: -8px;
}

.menu-button::after {
  content: '';
  margin-top: 8px;
}

#menu-toggle:checked + .menu-button-container .menu-button::before {
  margin-top: 0px;
  transform: rotate(405deg);
}

#menu-toggle:checked + .menu-button-container .menu-button {
  background: rgba(255, 255, 255, 0);
}

#menu-toggle:checked + .menu-button-container .menu-button::after {
  margin-top: 0px;
  transform: rotate(-405deg);
}

@media screen and (max-width: 1300px) {
  .navigation {
    font-size: 1.8rem;
  }
}

@media (max-width: 700px) {

  .header {
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  .menu-button-container {
    display: flex;
    padding-right: 35px;
  }

  .navigation {
    display: none;
    opacity: 0.95;
    margin: 0px;
    padding: 0px;
    height: 0px;
    a {
    height: 0px;
    margin: 0px;
    padding: 0.4em 0;
    width: 100%;
    color: black;
    background-color: white;
    text-align: center;
    }
    .nav-active {
      &::after {
        width: 10%;
        height: 1px;
        bottom: 8px;
      }
    }
  }

  #menu-toggle:checked ~ .navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    z-index: 5;
    height: auto;
    border-top: 1px solid;
    border-bottom: 0px;
    border-image: linear-gradient( 90deg, #e8751a, #f8da5b, #f8da5b, #e8751a)2;
    box-shadow: 2px 3px 5px 0px $grey;
    a {
      height: auto;
      transition: height 6s ease;
    }
  }
  
  #menu-toggle:checked ~ .header-container {
    border-bottom: none;
  }
}