@import url("//fonts.googleapis.com/css?family=Oswald");
@import url('https://fonts.googleapis.com/css2?family=PT+Sans+Narrow&family=Raleway:wght@100;300;400&display=swap');


$font-1: 'Final Fantasy', Oswald, 'Noto sans', sans-serif;
$font-2: 'Raleway', sans-serif;
$font-3: Oswald, 'Noto sans', sans-serif;
$quote: 'Rubik';


$white: #f5f5efec;
$black: black;
$yellow:#ffdd67;
$blue: #1a73e8;
$grey: #5c636e;
$grey2: #e3e3e3;
$red: #dc2f2f;
$background: #f7f7f7;


body {
  font-size: 20px;
  margin: 107px 0px 0px 0px;
  font-family: $font-3;
  background-color: $background;
  color: $black;
  box-sizing: border-box;
}

